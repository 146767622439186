.react-datepicker-wrapper {
  flex-grow: 1;
}

/* .react-datepicker__input-container > input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} */

.react-datepicker-popper {
  z-index: 9;
}
