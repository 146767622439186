.flag-sprite {
  display: inline-block;
  width: 16px;
  height: 11px;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  image-rendering: -o-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  background-image: url("Country.png");
}
.flag-a {
  background-position-x: 0;
}
.flag-_a {
  background-position-y: 0;
}
.flag-b {
  background-position-x: -16px;
}
.flag-_b {
  background-position-y: -11px;
}
.flag-c {
  background-position-x: -32px;
}
.flag-_c {
  background-position-y: -22px;
}
.flag-d {
  background-position-x: -48px;
}
.flag-_d {
  background-position-y: -33px;
}
.flag-e {
  background-position-x: -64px;
}
.flag-_e {
  background-position-y: -44px;
}
.flag-f {
  background-position-x: -80px;
}
.flag-_f {
  background-position-y: -55px;
}
.flag-g {
  background-position-x: -96px;
}
.flag-_g {
  background-position-y: -66px;
}
.flag-h {
  background-position-x: -112px;
}
.flag-_h {
  background-position-y: -77px;
}
.flag-i {
  background-position-x: -128px;
}
.flag-_i {
  background-position-y: -88px;
}
.flag-j {
  background-position-x: -144px;
}
.flag-_j {
  background-position-y: -99px;
}
.flag-k {
  background-position-x: -160px;
}
.flag-_k {
  background-position-y: -110px;
}
.flag-l {
  background-position-x: -176px;
}
.flag-_l {
  background-position-y: -121px;
}
.flag-m {
  background-position-x: -192px;
}
.flag-_m {
  background-position-y: -132px;
}
.flag-n {
  background-position-x: -208px;
}
.flag-_n {
  background-position-y: -143px;
}
.flag-o {
  background-position-x: -224px;
}
.flag-_o {
  background-position-y: -154px;
}
.flag-p {
  background-position-x: -240px;
}
.flag-_p {
  background-position-y: -165px;
}
.flag-q {
  background-position-x: -256px;
}
.flag-_q {
  background-position-y: -176px;
}
.flag-r {
  background-position-x: -272px;
}
.flag-_r {
  background-position-y: -187px;
}
.flag-s {
  background-position-x: -288px;
}
.flag-_s {
  background-position-y: -198px;
}
.flag-t {
  background-position-x: -304px;
}
.flag-_t {
  background-position-y: -209px;
}
.flag-u {
  background-position-x: -320px;
}
.flag-_u {
  background-position-y: -220px;
}
.flag-v {
  background-position-x: -336px;
}
.flag-_v {
  background-position-y: -231px;
}
.flag-w {
  background-position-x: -352px;
}
.flag-_w {
  background-position-y: -242px;
}
.flag-x {
  background-position-x: -368px;
}
.flag-_x {
  background-position-y: -253px;
}
.flag-y {
  background-position-x: -384px;
}
.flag-_y {
  background-position-y: -264px;
}
.flag-z {
  background-position-x: -400px;
}
.flag-_z {
  background-position-y: -275px;
}
